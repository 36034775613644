// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../../styles/config.scss";

.nav {
  border-bottom: 1px solid $gray-400;
  background-color: $white;

  .logo {
    height: 30px;
    width: 30px;
  }

  .title {
    font-family: $headings-font-family;
    margin-left: $spacer-1;
    vertical-align: middle;
    line-height: 30px;
  }
}

.nav-v2 {
  background-color: $gray-200;
}
