// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../../styles/config.scss";

.card-title {
  font-size: $fs-standard;
  margin-bottom: $spacer-2;
}

.card-content {
  font-size: 1.8rem;
  font-weight: 300;
  font-family: $font-family-heading;
}

.currency-end {
  font-size: $fs-standard;
  font-weight: initial;
}