// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../../styles/config.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

$modal-padding-y: 2rem;
$modal-padding-x: 3rem;

@mixin modal-shared {
  padding: $modal-padding-y $modal-padding-x 0 $modal-padding-x;
  z-index: 3001;
  background-color: #FFF;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  border-radius: 10px;
  margin: 1rem auto;
  max-height: 95%; // Needed for overflow to work.

  @include media-breakpoint-down(md) {
    width: 95%;
  }
}

.modal-small {
  @include modal-shared;
  width: 35%;
}

.modal-default {
  @include modal-shared;
  width: 50%;
}

.modal-large {
  @include modal-shared;
  width: 80%;
}

/* Disable body scroll when modal is opening. */
.body--react-modal-open {
  overflow-y: hidden;
}

.modal-line {
  padding: 1rem 0;

  &:after {
    position: absolute;
    content: " ";
    display: block;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $gray-200;
  }
}

.modal-prompt-button {
  display: flex;
  flex-direction: row-reverse;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  > button {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &+ button {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-left: 0.5rem;

      &+ button {
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }
  }
}
