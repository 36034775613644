// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../styles/config.scss";

.color-icon {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  margin-right: $spacer-1;
  margin-top: 6px;
  margin-bottom: 6px;
  vertical-align: text-top;
}
