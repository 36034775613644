/**
* Colors
*/
$green-100: mix($white, $green, 80%);
$green-200: mix($white, $green, 60%);
$green-300: mix($white, $green, 40%);
$green-400: mix($white, $green, 20%);
$green-500: $green;
$green-600: mix($black, $green, 20%);
$green-700: mix($black, $green, 40%);
$green-800: mix($black, $green, 60%);
$green-900: mix($black, $green, 80%);

$red-100: mix($white, $red, 80%);
$red-200: mix($white, $red, 60%);
$red-300: mix($white, $red, 40%);
$red-400: mix($white, $red, 20%);
$red-500: $red;
$red-600: mix($black, $red, 20%);
$red-700: mix($black, $red, 40%);
$red-800: mix($black, $red, 60%);
$red-900: mix($black, $red, 80%);

/**
* Font Size (fs)
*/
$fs-extra-large: 1.5rem;
$fs-large: 1.25rem;
$fs-standard: 1rem;
$fs-small: 0.75rem;
$fs-extra-small: 0.625rem;

 /**
 * Font Family
 */
$font-family-heading: "Fjalla One", "Arial Rounded MT Bold", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Bootstrap override
$headings-font-family: $font-family-heading;

/**
* Mixins (Content)
*/

@mixin text-content($font-size: $fs-standard) {
  font-size: $font-size;
}

/**
* Variables (Spacer)
*/
$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);

/**
* Buttons Mixin
*/

// Reset the browser default button style. Useful for implementing custom button with own style.
@mixin reset-button() {
  background-color: transparent;
  border: 0px;
  padding: 0;
  font-weight: inherit;
  text-align: initial;
}

// Reset the <a> styles. Useful for implementing custom link with own style.
@mixin reset-link() {
  text-decoration: none;

  &:hover {
    background-color: initial;
  }
}

/**
* Images Mixin
*/

// Makes the image automatically scale to fit the height specified.
@mixin image-fit-height($h) {
  height: $h;
  width: auto;
  display: block;
}

// Makes the image automatically scale to fit the width specified.
@mixin image-fit-width($w) {
height: auto;
width: $w;
display: block;
}
