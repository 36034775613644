// Since react-toastify does not provide options for custom class name, we would just include them as global.
@import 'react-toastify/dist/ReactToastify.minimal.css';

.Toastify__toast-container {
  z-index: 3003;
  position: fixed;
  width: 100%;
  padding: 0;

  &.Toastify__toast-container--bottom-center {
    bottom: 0;
    left: 0;
  }

  .Toastify__toast {
    @include text-content();
    color: $gray-900;
    background-color: $green-600;
    color: $white;
    padding: $spacer-2 $spacer-3;
    display: flex;
    height: 80px;
    overflow: hidden;
    text-align: center;
    align-items: center;

    &.Toastify__toast--error {
      background-color: $red-600;
    }

    .Toastify__toast-body {
      flex: 1;
    }

    .Toastify__close-button {
      color: $white;
      height: 1rem;
      width: 1rem;
      font-weight: bold;
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      opacity: 0.7;
      cursor: pointer;
      transition: 0.3s ease;
      align-self: flex-start;

      > svg {
        fill: currentColor;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  @keyframes slideIn {
    from {
      transform: translate(0, 80px, 0);
    }
  
    to {
      transform: translate(0, 0px, 0);
    }
  }
  
  .slideIn {
    animation-name: slideIn;
  }
  
  @keyframes slideOut {
    to {
      transform: translate(0, 0, 0);
    }
  }
  
  .slideOut {
    animation-name: slideOut;
  }
}
