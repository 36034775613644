// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "config.scss";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./dropDownList.scss";
@import "./toast.scss";
@import "./datePicker.scss";

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100vh;
  background-color: $gray-100;
}

// Turn off all resize for text areas.
textarea {
  resize: none;
}

// Reset Button Util
.btn-reset {
  @include reset-button();
}

// Util to normalize HTML
.normalize-html {
  white-space: initial;
}

// Util to show the line break in the text.
.multiline {
  white-space: pre-line;
}

// Relaxed rule to show invalid feedback
.form-group.has-danger .invalid-feedback {
  display: block;
}

// To force display some components
.force-show {
  display: block;
}
