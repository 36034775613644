// Since rc-select does not provide too many options for drop down lists, we would just include them as global.
.rc-select {
  position: relative;
  vertical-align: middle;
  padding: 0;
  cursor: text;

  &.rc-select-focused {
    border-color: $gray-900;
  }

  &.rc-select-disabled {
    background-color: $gray-200;
  }

  > .rc-select-selector {
    position: relative;
    height: 100%;
    outline: none;
    border: none;
    padding: 0.375rem 0.75rem;

    > .rc-select-selection-item, > .rc-select-selection-placeholder {
      position: absolute;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 0.375rem 0.75rem;
    }

    > .rc-select-selection-placeholder {
      color: $gray-600;
    }

    > .rc-select-selection-search {
      outline: none;
      border: none;

      .rc-select-selection-search-input {
        padding: 0;
        width: 100%;
        outline: none;
        border: none;
      }
    }
  }

  > .rc-select-arrow {
    height: 100%;
    top: 0;
    right: 1rem;
    position: absolute;
    pointer-events: none;
    outline: none;

    > .rc-select-arrow-icon::after {
      position: absolute;
      content: ' ';
      border-color: $gray-600 transparent transparent transparent;
      border-style: solid;
      border-width: 7px 4px 0 4px;
      height: 0;
      width: 0;
      top: calc(50% - 3px);
    }
  }
}

.rc-select-dropdown {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 4px;
  z-index: 3;
  position: absolute;
  outline: none;

  &.rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item-option, .rc-select-item-empty {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    padding: 0 10px;
    min-height: 40px;
    line-height: 40px;
    word-break: break-all;
    width: 100%;

    &.rc-select-item-option-selected {
      background-color: $gray-200;
      font-weight: 700;

      > .rc-select-item-option-state {
        position: absolute;
        right: $spacer-3;
        pointer-events: none;
        top: 0px;
      }
    }

    &.rc-select-item-option-disabled {
      color: $gray-600;
    }

    &.rc-select-item-option-active {
      cursor: pointer;
      background-color: $gray-300;
    }
  }
}
