// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../../styles/config.scss";

p, h5{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p{
    margin-bottom: 0;
}

small{
    color: rgb(148,163,184);
}

.badge{
    font-size: 85%;
}
.table thead th {
    vertical-align: middle;
    text-align: center;
}

tr td{
    text-align: center;
}