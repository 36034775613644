// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../styles/config.scss";

.wrap {
  min-height: 400px;
  max-height: 95%;

  padding-bottom: $spacer-3;
}

.input-column {
  display: flex;
  flex-direction: row;

  > input {
    flex: 1;
    margin-right: $spacer-1;
  }
}
