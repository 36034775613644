// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../../../styles/config.scss";

.btn-title {
  border-radius: 4px;
  margin-right: $spacer-2;
  padding: $spacer-1 $spacer-2;
  display: flex;
  align-items: center;
  color: $gray-700;

  &:hover {
    background-color: $gray-200;
  }

  &:active {
    background-color: $gray-300;
  }
}

.leftfix {
  margin-left: -$spacer-2;
}
