// This file would be injected to every css.
// DO NOT put any actual rule here as it would get duplicated for every scss file imported.
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";
@import "../styles/config.scss";

.quick-links-container {
  display: flex;
  flex-direction: column;
}
